import React, { useState, useEffect } from "react";
import "../styles/form.css";
import img from "../../assets/heroBack.webp";
import axios from "axios";

const Form = () => {
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    phoneNo: "",
    email: "",
    city: "",
    state: "",
    address: "",
    zipCode: "",
    dob: "",
    terms: false,
    leadid_token: "",
    incident_date: "",
    injury_Type: "",
    injury_occurred: false,
    at_fault: false,
    currently_represented: false,
    needs_attorney: "yes",
    person_at_fault: false,
    hospitalized_or_treated: false,
    area_of_practice: "",
    currently_insured: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.id = 'LeadiDscript_campaign';
  //   script.type = 'text/javascript';
  //   script.async = true;
  //   script.src = '//create.lidstatic.com/campaign/371b0c15-99da-f776-20d8-55d1b555ea9a.js?snippet_version=2';

  //   document.head.appendChild(script);

  //   const noscriptImg = document.createElement('img');
  //   noscriptImg.src = '//create.leadid.com/noscript.gif?lac=5A971849-8670-DE38-A5BA-2412B4F7EEC6&lck=371b0c15-99da-f776-20d8-55d1b555ea9a&snippet_version=2';
  //   document.body.appendChild(noscriptImg);

  //   return () => {
  //     document.head.removeChild(script);
  //     document.body.removeChild(noscriptImg);
  //   };
  // }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const leadidToken = document.getElementById("leadid_token").value;
      const trustedform_cert_url = document.getElementById("xxTrustedFormCertUrl_0").value;
      console.log("lead token", leadidToken);
      console.log("trustedform_cert_url", trustedform_cert_url)
      const response = await axios.post("/submit", {
        ...formData,
        leadid_token: leadidToken,
        trustedform_cert_url: trustedform_cert_url,
      });
      console.log("Form submitted successfully:", response.data);
      setStatus(1);
      setMsg("Form Submitted Sucessfully");
      setFormData({
        fname: "",
        lname: "",
        phoneNo: "",
        email: "",
        city: "",
        state: "",
        address: "",
        zipCode: "",
        dob: "",
        terms: false,
        leadid_token: "", 
        incident_date: "",
        injury_Type: "",
        injury_occurred: false,
        at_fault: false,
        currently_represented: false,
        needs_attorney: "",
        person_at_fault: false,
        hospitalized_or_treated: false,
        area_of_practice: "",
        currently_insured: false,
        leadid_tcpa_disclosure:false,
      });
    } catch (error) {
      setMsg("Sorry! Fiald to submit Please submit again");
      setStatus(2);
      console.error("Error submitting form:", error);
      console.error("Error details:", error.response);
    }
  };

  return (
    <div
      className="container"
      style={{ backgroundImage: `url(${img})`, backgroundSize: "cover" }}
    >
      <div className="left-side">
        <h2>
          Drive Worry-Free: Your Ultimate Coverage for Motor Vehicle Accidents
        </h2>
        <p className="policy">
          Manage Your Policy Anytime, Anywhere, with No Hassles
        </p>
      </div>
      <div className="right-side">
        <div className="form-area">
          <form onSubmit={handleSubmit} id="formData" className="formData">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="fname">First Name:</label>
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  value={formData.fname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lname">Last Name:</label>
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  value={formData.lname}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phoneNo">Phone NO</label>
                <input
                  type="text"
                  id="phoneNo"
                  name="phoneNo"
                  value={formData.phoneNo}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email ID:</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="zipCode">Zip Code:</label>
                <input
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="dob">DOB</label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="incident_date">Incident Year</label>
                  <select
                    id="incident_date"
                    name="incident_date"
                    value={formData.incident_date}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Within 1 Year">Within 1 Year</option>
                    <option value="Within 2 Year">Within 2 Year</option>
                    <option value="Within 3 Year">Within 3 Year</option>
                    <option value="Within 4 Year">Within 4 Year</option>
                    <option value="Within 5 Year">Within 5 Year</option>
                    <option value="Within 6 Year">Within 6 Year</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="injury_Type">Injury Type</label>
                  <select
                    name="injury_Type"
                    id="injury_Type"
                    onChange={handleChange}
                  >
                    <option value="">Selet option</option>
                    <option value="Anxiety">Anxiety</option>
                    <option value="Back or Neck Pain">Back or Neck Pain</option>
                    <option value="Brain Injury">Brain Injury</option>
                    <option value="Broken Bones">Broken Bones</option>
                    <option value="Cuts and Bruises">Cuts and Bruises</option>
                    <option value="Headaches">Headaches</option>
                    <option value="Loss of Life">Loss of Life</option>
                    <option value="Loss of Lims">Loss of Limb</option>
                    <option value="Memory Loss">Memory Loss</option>
                    <option value="Spinal Cord Injury or Paralysis">
                      Spinal Cord Injury or Paralysis
                    </option>
                    <option value="Whiplash">Whiplash</option>
                    <option value="no injury">no injury</option>
                  </select>
                </div>
              </div>
            </div>
            <label htmlFor="leadid_tcpa_disclosure">
              <input type="checkbox" 
                id="leadid_tcpa_disclosure"
                name="leadid_tcpa_disclosure"
                checked={formData.leadid_tcpa_disclosure}
               onChange={handleChange}
               required
              />
              </label>
              By clicking “Submit”, I provide my express written consent via electronic signature authorizing eazylegalclaim Team and one or more law firms, their agents and marketing partners to contact me about my case and other related legal products and services to the number and email address I provided (including any wireless number). I further expressly consent to receive telemarketing emails, calls, text messages, pre-recorded messages, and artificial voice messages via an autodialed phone system, even if my telephone number is a mobile number that is currently listed on any state, federal or corporate “Do Not Call” list. I understand that my consent is not a condition of purchase of any goods or services and that standard message and data rates may apply.
              <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>
            <input
              type="hidden"
              name="xxTrustedFormCertUrl"
              id="xxTrustedFormCertUrl_0"
              value="https://cert.trustedform.com/454a35b802f3e7b63ffabb4efedb7c6ebe67886c"
            />
            <span>Status: {status}</span>
            <button className="sub-btn" id="subBtn" type="submit">
              Submit
            </button>
          </form>
              {status === 1 && <span style={{ color: 'green' }}>Form Submitted Successfully</span>}
              {status === 2 && <span style={{ color: 'red' }}>Sorry! Failed to submit. Please try again.</span>}
          <p>We promise your details are secure with us.</p>
        </div>
      </div>
    </div>
  );
};

export default Form;
