import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/table.css";

const PortalPage = () => {
  const [data, setData] = useState([]);
  const [businessNumber, setBusinessNumber] = useState({});
  const [publisherPayout, setPublisherPayout] = useState({});
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get("/api/portal");
      setData(response.data);
      console.log("Data fetched successfully:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleD1Click = async (leadId) => {
    try {
        console.log("Lead ID token:", leadId);
        const fetchDataResponse = await axios.get("/api/portal");
        const newData = fetchDataResponse.data;

        console.log("New Data:", newData);
        const leadData = newData.find((item) => item._id === leadId);
        console.log("Lead Data:", leadData);

        if (!leadData) {
            console.error("Lead data not found for lead ID:", leadId);
            return;
        }
        const requestData = {
            caller_id: leadData.phoneNo,
            first_name: leadData.fname,
            last_name: leadData.lname,
            has_attorney: false,
            hospitalized_or_treated: "yes",
            incident_date: leadData.incident_date,
            lead_token: "d86b5083195041e786d50a02c3a556d9",
            person_at_fault: "no",
            source_url: "https://eazylegalclaim.com",
            traffic_source_id: "11901",
            zip: leadData.zipCode,
        };
        console.log("Request Data:", requestData);
        const trackDriveResponse = await axios.post(
            "/api/track-drive",
            requestData
        );
        console.log("Response:", trackDriveResponse);

        if (trackDriveResponse.status === 200) {
            console.log("Data successfully sent to Track Drive");
            const formattedResponse = JSON.stringify(trackDriveResponse.data, null, 2);
            const newTab = window.open();
            const html = `
                <html>
                    <head>
                        <title>Track Drive Response</title>
                    </head>
                    <body>
                        <pre>${formattedResponse}</pre>
                    </body>
                </html>
            `;
            newTab.document.write(html);
        } else {
            console.log("Failed to send data to Track Drive");
        }
    } catch (error) {
        console.error("Error sending data to Track Drive:", error);
    }
};

  const handleRTBClick = async (leadidToken) => {
    try {
      const leadData = data.find((item) => item._id["$oid"] === leadidToken);
      if (!leadData) {
        console.error("Lead data not found for lead ID:", leadidToken);
        return;
      }
      const partnerId = "zu0fcp4k";
      const searchType = "keyword";
      const searchTerm = "MVA Attorneys";
      const zip = leadData.zipCode;
      const url = `https://services.archenia.io/mcm/listings?partnerId=${partnerId}&searchType=${searchType}&searchTerm=${searchTerm}&zip=${zip}`;
      const response = await fetch(url);
      if (response.ok) {
        const responseData = await response.json();
          const htmlContent = JSON.stringify(responseData, null, 2);
        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(`<pre>${htmlContent}</pre>`);
        newTab.document.close();
      } else {
        console.error("Failed to fetch data:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const handleD5ButtonClick = async (leadId) => {
    const fetchDataResponse = await axios.get("/api/portal");
      const newData = fetchDataResponse.data;

      console.log("New Data:", newData);
      const leadData = newData.find((item) => item._id === leadId);
      console.log("Lead Data:", leadData);

      if (!leadData) {
        console.error("Lead data not found for lead ID:", leadId);
        return;
      }
    try {
      const userData = {
        accident_vehicle: "car",
        phoneNo: leadData.phoneNo,
        auto_accident_in_past_2_years: "yes",
        currently_represented: "yes",
        email:leadData.email,
        first_name: leadData.fname,
        hospitalized_or_treated:leadData.hospitalized_or_treated,
        injury_type: leadData.injury_type,
        Bruises:"Anxiety",
        ip_address: leadData.ip_address,
        last_name:leadData.lname,
        person_at_fault:"yes",
        landing_page:leadData.source_url,
        state: leadData.state,
        zip: leadData.zipCode,
        date_accident: leadData.date_accident,
        spoken_language:"English",
      };

      const response = await axios.post('/d5', userData);
      console.log('Response from D5:', response.data);
    } catch (error) {
      console.error('Error calling D5 endpoint:', error);
    }
};
const handleD6ButtonClick = async (leadId) => {
    try {
      console.log("Lead ID token:", leadId);
      const fetchDataResponse = await axios.get("/api/portal");
      const newData = fetchDataResponse.data;

      console.log("New Data:", newData);
      const leadData = newData.find((item) => item._id === leadId);
      console.log("Lead Data:", leadData);

      if (!leadData) {
        console.error("Lead data not found for lead ID:", leadId);
        return;
      }
      const requestData = {
        trackdrive_number: "+18665531915",
        traffic_source_id: 15224,
        caller_id: leadData.phoneNo,
        ping_id: "ping",
        first_name: leadData.fname,
        last_name: leadData.lname,
        email: leadData.email,
        zip: leadData.zip,
        traffic_source_id: "15224",
        source_url:leadData.source_url,
        trusted_form_cert_url: "",
        incident_date: leadData.incident_date,
        injury_type: leadData.injury_type,
      };
      const response = await axios.post("/d6", requestData);

      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleD7ButtonClick = async (leadId) => {
    if (!leadId) {
        console.error("No user selected.");
        return;
    }
    try {
        const fetchDataResponse = await axios.get("/api/portal");
        const newData = fetchDataResponse.data;

        console.log("New Data:", newData);
        const selectedUser = newData.find((user) => user._id === leadId);
        if (!selectedUser) {
            console.error("User not found.");
            return;
        }
        
      const payload = {
        lp_campaign_id: "20730",
        lp_supplier_id: "52747",
        lp_key: "kq2h63k7i0vrv",
        lp_action: "no",
        lp_subid1: "no",
        lp_subid2: "no",
        first_name: selectedUser.fname,
        last_name: selectedUser.lname,
        email: selectedUser.email,
        phone:selectedUser.phoneNo,
        state: "CA",
        city: selectedUser.city,
        zip_code: "78124",
        ip_address: "127.0.0.1",
        user_agent: "Ali",
        landing_page_url: "https://eazylegalclaim.com",
        jornaya_leadid: selectedUser.leadid_token,
        trustedform_cert_url: selectedUser.trustedform_cert_url, 
        case_description: "no",
        accident_date:"2023-07-21",
        medical_treatment_date: "2023-07-21",
        at_fault:"no",
        have_attorney:"no",
        defendant_had_insurance:"yes",
        plaintiff_had_uninsured_motorist_coverage: "no",
        tcpa_text: "sunday",
        sub1: "August",
      };
      const response = await axios.post("https://api.leadprosper.io/ingest", payload);
      console.log("Response from D7:", response.data);
    } catch (error) {
      console.error("Error calling D7 endpoint:", error);
    }
  };
  const handleD8ButtonClick = async (leadId) => {
    try {
      console.log("Lead ID token:", leadId);
      const fetchDataResponse = await axios.get("/api/portal");
      const newData = fetchDataResponse.data;

      console.log("New Data:", newData);
      const leadData = newData.find((item) => item._id === leadId);
      console.log("Lead Data:", leadData);

      if (!leadData) {
        console.error("Lead data not found for lead ID:", leadId);
        return;
      }
      const requestData = {
        lead_token:"f020e6405f2249dc8335eeb7320916df",
        caller_id: leadData.phoneNo,
        ip_address: leadData.ip_address,
        first_name: leadData.fname,
        last_name: leadData.lname,
        email: leadData.email,
        state: leadData.state,
        zip: leadData.zipCode,
        currently_represented:"No",
        hospitalized_or_treated: "No",
        person_at_fault: "No",
        person_at_fault:"yes",
        auto_accident_in_past_2_years:"yes",
        injury_type:"Loss of Life",
        spoken_language:"English",
        trusted_form_cert_url:""
      };
      console.log("Request Data:", requestData);
      const trackDriveResponse = await axios.post(
        "/d8",       
         requestData
      );
      console.log("Response:", trackDriveResponse);

      if (trackDriveResponse.status === 200) {
        console.log("Data successfully sent to Track Drive");
      } else {
        console.log("Failed to send data to Track Drive");
      }
    } catch (error) {
      console.error("Error sending data to Track Drive:", error);
    }
  };
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>F Name</th>
            <th>L Name</th>
            <th>Phone No</th>
            <th>Email</th>
            <th>City</th>
            <th>State</th>
            <th>Address</th>
            <th>Zip Code</th>
            <th>DOB</th>
            <th>Lead Id</th>
            <th>Ip Address</th>
            <th>Created At</th>
            <th>D1</th>
            <th>D2</th>
            <th>D3</th>
            <th>D5</th>
            <th>D6</th>
            <th>D7</th>
            <th>D8</th>
            <th>D9</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data?.map((item) => (
              <tr key={item?._id["$oid"]}>
                <td>{item?.fname}</td>
                <td>{item?.lname}</td>
                <td>{item?.phoneNo}</td>
                <td>{item?.email}</td>
                <td>{item?.city}</td>
                <td>{item?.state}</td>
                <td>{item?.address}</td>
                <td>{item?.zipCode}</td>
                <td>{item?.dob}</td>
                <td>{item?.leadid_token}</td>
                <td>{item?.ip_address}</td>
                <td>{item?.createdAt}</td>
                <td>
                  <button
                    className="button-link"
                    onClick={() => handleD1Click(item?._id)}
                  >
                    D1
                  </button>
                </td>

                <td>
                  <button
                    className="button-link"
                    onClick={() => handleRTBClick(item?._id["$oid"])}
                  >
                    D2
                  </button>
                </td>
                <td>{/* <button className="button-link">D3</button> */}</td>
                <td>
                  <button className="button-link" 
                    onClick={() => handleD5ButtonClick(item?._id)}
                    >D5
                  </button>
                  </td>
                <td>
                  <button
                    className="button-link"
                    onClick={() => handleD6ButtonClick(item?._id)}
                    >
                    D6
                  </button>
                </td>
                <td>
                <button className="button-link" 
                    onClick={() => handleD7ButtonClick(item?._id)}
                    >D7
                  </button>               
                  </td>
                  <td>
                <button className="button-link" 
                    onClick={() => handleD8ButtonClick(item?._id)}
                    >D8
                  </button>               
                  </td>
                  <td>
                <button className="button-link" 
                    onClick={() => handleD8ButtonClick(item?._id)}
                    >D9
                  </button>               
                  </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PortalPage;
